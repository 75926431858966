import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 兑换码同步兑换（京东-永辉同步兑换能力）
export function syncVerify(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/syncVerify`,
    method: 'post',
    data
  })
}
// 发送短信
export function sendVerifyCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/sendVerifyCodeForSync`,
    method: 'post',
    data
  })
}
// 兑换码列表查询（京东-永辉同步兑换能力）
export function listRedeemCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/listRedeemCodeForSyncWithDescribe`,
    method: 'post',
    data
  })
}

// 兑换码列表查询
export function listOtherRedeemCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/listRedeemCode`,
    method: 'post',
    data
  })
}
